import React from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import sliderEn from '../assets/under_construction.png';
import sliderTr from '../assets/yapim_asamasinda.png';

function Preschool() {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const sliderImage = currentLanguage === 'tr' ? sliderTr : sliderEn;

  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    draggable: false,
    swipe: false,
  };

  return (
    <div>
      <Slider {...settings} className="slider-container">
        <div>
          <img src={sliderImage} alt="Slide" className="img-fluid" />
        </div>
      </Slider>
    </div>
  );
}

export default Preschool;
