import React from 'react';
import Slider from 'react-slick'; // react-slick slider kütüphanesi
import { useTranslation } from 'react-i18next';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css'; // Slider stil dosyaları
import './Home.css'; // Home.css dosyasını dahil ediyoruz
import sliderMain from '../assets/AnitKabir.jpg';
import slider1 from '../assets/Slider1.png';
import slider3 from '../assets/slider3.gif';
import slider5 from '../assets/Slider5.jpeg';
import slider6 from '../assets/Slider6.png';
import slider7 from '../assets/Slider7.png';
import slider8 from '../assets/Slider8.gif';
import slider9 from '../assets/Slider9.png';
import mainImageSolo from '../assets/main_image_solo.png'; // Resmin doğru yolu
import WhatsApp from '../components/WhatsApp';
function Home() {
  const { t } = useTranslation();

  // Slider ayarları
  const settings = {
    dots: true,
    infinite: true,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    arrows: true
  };

  return (
    <div>
      <div className="App">
        <WhatsApp />
      </div>
      {/* Slider */}
      <Slider {...settings} className="slider-container">

        <div>
          <img src={sliderMain} alt="Anıtkabir" className="img-fluid" />
        </div>
        <div>
          <img src={slider1} alt="Slide 1" className="img-fluid" />
        </div>
        {/* <div>
          <img src={slider2} alt="Slide 2" className="img-fluid" />
        </div> */}
        <div>
        <a href="/achievement">
  <img src={slider3} alt="Slide 3" className="img-fluid" />
</a>
        </div>
        {/* <div>
          <img src={slider4} alt="Slide 4" className="img-fluid" />
        </div> */}
        <div>
          <img src={slider5} alt="Slide 5" className="img-fluid" />
        </div>
        <div>
          <img src={slider6} alt="Slide 6" className="img-fluid" />
        </div>
        <div>
          <img src={slider7} alt="Slide 7" className="img-fluid" />
        </div>
        <div>
          <img src={slider8} alt="Slide 8" className="img-fluid" />
        </div>
        <div>
          <img src={slider9} alt="Slide 9" className="img-fluid" />
        </div>
      </Slider>

      {/* Başlık ve Mesaj */}
      <section data-type="component-text">
        <section className="container pt-5 pb-5">

          <div className="row">
            <div className="col-lg-6 col-sm-12 mb-5">

              <p>{t('main_text_1')}</p>
              <p>{t('main_text_2')}</p>
              <p>{t('main_text_3')}</p>
              <p>{t('main_text_4')}</p>
              <p>{t('main_text_5')}</p>
              <p>{t('main_text_6')}</p>
            </div>

            <div className="col-lg-6 col-sm-12">
              <div className="full-width-image-container">
                <img src={mainImageSolo} alt="MatBilim Logo" className="full-width-image" />
              </div>
            </div>
          </div>
        </section>

        {/* News Section */}
        {/* <section className="pt-5 pb-5 news">
          <h1 className="pb-3 text-center text-white">{t('news')}</h1>
          <div className="row justify-content-center">
            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
              <Card imageUrl={mainImage} description={description} />
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
              <Card imageUrl={mainImage} description={description} />
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
              <Card imageUrl={mainImage} description={description} />
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
              <Card imageUrl={mainImage} description={description} />
            </div>
          </div>
        </section> */}

        {/* Activities Section */}
        {/* <section className="pt-5 pb-5 activities">
          <h1 className="pb-3 text-center">{t('activities')}</h1>
          <div className="row justify-content-center">
            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
              <Card imageUrl={mainImage} description={description} />
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
              <Card imageUrl={mainImage} description={description} />
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
              <Card imageUrl={mainImage} description={description} />
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
              <Card imageUrl={mainImage} description={description} />
            </div>
          </div>
        </section> */}
      </section>
    </div>
  );
}

export default Home;
