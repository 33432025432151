import React from 'react';
import achievement1 from '../assets/GururTablosu/GururTablosu_1.jpeg';
import achievement2 from '../assets/GururTablosu/GururTablosu_2.jpeg';
import achievement3 from '../assets/GururTablosu/GururTablosu_3.jpeg';
import achievement4 from '../assets/GururTablosu/GururTablosu_4.jpeg';
import achievement5 from '../assets/GururTablosu/GururTablosu_5.jpeg';
import achievement6 from '../assets/GururTablosu/GururTablosu_6.jpeg';
import achievement7 from '../assets/GururTablosu/GururTablosu_7.jpeg';
import achievement8 from '../assets/GururTablosu/GururTablosu_8.jpeg';
import achievement9 from '../assets/GururTablosu/GururTablosu_9.jpeg';
import achievement10 from '../assets/GururTablosu/GururTablosu_10.jpeg';
import achievement11 from '../assets/GururTablosu/GururTablosu_11.jpeg';
import achievement12 from '../assets/GururTablosu/GururTablosu_12.jpeg';
import achievement13 from '../assets/GururTablosu/GururTablosu_13.jpeg';
import achievement14 from '../assets/GururTablosu/GururTablosu_14.jpeg';
import achievement15 from '../assets/GururTablosu/GururTablosu_15.jpeg';
import achievement16 from '../assets/GururTablosu/GururTablosu_16.jpeg';
import achievement17 from '../assets/GururTablosu/GururTablosu_17.jpeg';
import achievement18 from '../assets/GururTablosu/GururTablosu_18.jpeg';
import achievement19 from '../assets/GururTablosu/GururTablosu_19.jpeg';
import achievement20 from '../assets/GururTablosu/GururTablosu_20.jpeg';
import achievement21 from '../assets/GururTablosu/GururTablosu_21.jpeg';
import achievement22 from '../assets/GururTablosu/GururTablosu_22.jpeg';
import achievement23 from '../assets/GururTablosu/GururTablosu_23.jpeg';

function Achievement() {
  return (
    <div>
      <div className="empty-space_init"></div>
      <div className="empty-space"></div>
      <section className="container pt-5 pb-5 text-center">
        <img src={achievement1} alt="Soon" className="centered-image" />
        <img src={achievement2} alt="Soon" className="centered-image" />
        <img src={achievement3} alt="Soon" className="centered-image" />
        <img src={achievement4} alt="Soon" className="centered-image" />
        <img src={achievement5} alt="Soon" className="centered-image" />
        <img src={achievement6} alt="Soon" className="centered-image" />
        <img src={achievement7} alt="Soon" className="centered-image" />
        <img src={achievement8} alt="Soon" className="centered-image" />
        <img src={achievement9} alt="Soon" className="centered-image" />
        <img src={achievement10} alt="Soon" className="centered-image" />
        <img src={achievement11} alt="Soon" className="centered-image" />
        <img src={achievement12} alt="Soon" className="centered-image" />
        <img src={achievement13} alt="Soon" className="centered-image" />
        <img src={achievement14} alt="Soon" className="centered-image" />
        <img src={achievement15} alt="Soon" className="centered-image" />
        <img src={achievement16} alt="Soon" className="centered-image" />
        <img src={achievement17} alt="Soon" className="centered-image" />
        <img src={achievement18} alt="Soon" className="centered-image" />
        <img src={achievement19} alt="Soon" className="centered-image" />
        <img src={achievement20} alt="Soon" className="centered-image" />
        <img src={achievement21} alt="Soon" className="centered-image" />
        <img src={achievement22} alt="Soon" className="centered-image" />
        <img src={achievement23} alt="Soon" className="centered-image" />
      </section>
    </div>
  )
}
export default Achievement;