import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Staff.css';
import manager1 from '../staff/high/İLKER İHSAN RECEP NAFİLE MATEMATİK.jpg'
import manager2 from '../staff/high/gönül koparal coğrafya lise.jpeg'
import manager4 from '../staff/high/bülent tekin lise müdür yardımcısı.jpeg'
import manager3 from '../staff/primary_secondary/LEYLA FUNDA GÜNBAY İLKOKUL-ORTAOKUL MÜDÜRÜ.jpg'
import manager5 from '../staff/primary_secondary/aybüke koçak ilkokul - ortaokul müdür yardımcısı .jpeg'
import manager6 from '../staff/high/esra düzen kurs müdürü.jpeg'
import manager7 from '../staff/high/gönül koparal coğrafya lise ayakta.jpeg'
import teacher_primary_1 from '../staff/primary_secondary/şevval bostan rehber öğretmen ilkokul-ortaokul.jpeg'
import teacher_primary_2 from '../staff/primary_secondary/ercan güngör sınıf öğretmeni.jpeg'
import teacher_primary_3 from '../staff/primary_secondary/kemal ay sınıf öğretmeni.jpeg'
import teacher_primary_4 from '../staff/primary_secondary/hamra şamlıoğlu matematik.jpeg'
import teacher_primary_5 from '../staff/primary_secondary/DAMLA ERDOĞAN ORTAOKUL MATEMATİK ÖĞRETMENİ.jpeg'
import teacher_primary_6 from '../staff/primary_secondary/ayperen özveren ingilizce ilkokul.jpeg'
import teacher_primary_7 from '../staff/primary_secondary/ceren özbay ingilizce ilkokul-ortaokul.jpeg'
import teacher_primary_8 from '../staff/primary_secondary/DENİZ SİMAY ATLIHAN İNGİLİZCE.jpg'
import teacher_primary_9 from '../staff/primary_secondary/PELİN ŞİRİN İNGİLİZCE İLOKUL.jpeg'
import teacher_primary_10 from '../staff/primary_secondary/buse rüzgar atak sosyal bilgiler ORTAOKUL.jpeg'
import teacher_primary_11 from '../staff/primary_secondary/DUYGU YARAR ORTAOKUL TÜRKÇE ÖĞRETMENİ.jpeg'
import teacher_primary_12 from '../staff/primary_secondary/BETÜL CANDEMİR FEN BİLİMLERİ ORTAOKUL.jpg'
import teacher_primary_13 from '../staff/primary_secondary/yasemin yiğit topcan fen bilimleri ortaokul.jpg'
import teacher_primary_14 from '../staff/primary_secondary/aysun güngör görsel sanatlar İLKOKUL-ORTAOKUL.jpeg'
import teacher_primary_15 from '../staff/primary_secondary/dilara akar bilişim teknolojileri.jpg'
import teacher_primary_16 from '../staff/primary_secondary/metin ozan demirelli beden eğitimi İLKOKUL-ORTAOKUL.jpeg'
import teacher_primary_17 from '../staff/primary_secondary/ESİN ÇAĞATAY KOÇMAR İLKOKUL-ORTAOKUL ÖĞRENCİ İŞLERİ.jpg'
import teacher_primary_18 from '../staff/primary_secondary/KEZBAN ATASOY GÜVENLİK GÖREVLİSİ.jpg'
import teacher_primary_19 from '../staff/primary_secondary/Arya Yılmaz Müzik Öğretmeni.jpeg'
import teacher_primary_20 from '../staff/primary_secondary/Burçin Koç -İngilizce Öğretmeni.jpeg'
import teacher_high_1 from '../staff/high/REHBER ÖĞRETMEN_şükran aktan.jpeg'
import teacher_high_2 from '../staff/high/ÖLÇME DEĞERLENDİRME UZMANI_irem çağlar.jpeg'
import teacher_high_3 from '../staff/high/MATEMATİK_ikram demir.jpeg'
import teacher_high_4 from '../staff/high/MATEMATİK_kemal çopur .jpeg'
import teacher_high_5 from '../staff/high/MATEMATİK_selmin kök.jpeg'
import teacher_high_6 from '../staff/high/MATEMATIK_turgut demir.jpeg'
import teacher_high_7 from '../staff/high/İNGİLİZCE_CEMİL ERDEM BİLGİÇ.jpg'
import teacher_high_8 from '../staff/high/TARİH_mert düzen.jpeg'
import teacher_high_9 from '../staff/high/COĞRAFYA_mustafa merdan demir.jpeg'
import teacher_high_10 from '../staff/high/DİN KÜLTÜRÜ VE AHLAK BİLGİSİ_kübra bayram.jpeg'
import teacher_high_11 from '../staff/high/TÜRK DİLİ VE EDEBİYATI_hanife güney.jpeg'
import teacher_high_12 from '../staff/high/TÜRKÇE_mehmet çakır.jpeg'
import teacher_high_13 from '../staff/high/FİZİK_ahmet tutar.jpg'
import teacher_high_14 from '../staff/high/FİZİK_celalettin sezgin.jpg'
import teacher_high_15 from '../staff/high/BİYOLOJİ_fuat ertuğrul.jpeg'
import teacher_high_16 from '../staff/high/BİYOLOJİ_jale çelik.jpeg'
import teacher_high_17 from '../staff/high/KİMYA_aylin tutar.jpeg'
import teacher_high_18 from '../staff/high/BEDEN EĞİTİMİ_MUSTAFA AYDOĞAN.jpg'
import teacher_high_19 from '../staff/high/ÖĞRENCİ İŞLERİ_MİNE ALTUNIŞIK.jpeg'
import teacher_high_20 from '../staff/high/MEMUR_HİLAL CANER ARPA.jpg'
import teacher_high_21 from '../staff/high/İsmet Şimşek Fizik Öğretmeni.jpeg'
import teacher_high_22 from '../staff/high/Uğur KARABALAK.jpeg'

function Staff() {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState("management");
    const [selectedImage, setSelectedImage] = useState(null);

    const primaryTeachers = [
        { name: t('Leyla Funda GÜNDAY'), role: t('İlkokul & Ortaokul Müdürü'), image: manager3 },
        { name: t('Aybüke KOÇAK'), role: t('İlkokul & Ortaokul Müdür Yardımcısı'), image: manager5 },
        { name: t('Şevval BOSTAN'), role: t('Rehber Öğretmen ve Psikolojik Danışman'), image: teacher_primary_1 },
        { name: t('Ercan GÜNGÖR'), role: t('Sınıf Öğretmeni'), image: teacher_primary_2 },
        { name: t('Kemal AY'), role: t('Sınıf Öğretmeni'), image: teacher_primary_3 },
        { name: t('Hamra ŞAMLIOĞLU'), role: t('Matematik Öğretmeni'), image: teacher_primary_4 },
        { name: t('Damla ERDOĞAN'), role: t('Matematik Öğretmeni'), image: teacher_primary_5 },
        { name: t('Ayperen ÖZVEREN'), role: t('İngilizce Koordinatörü'), image: teacher_primary_6 },
        { name: t('Ceren ÖZBAY'), role: t('İngilizce Öğretmeni'), image: teacher_primary_7 },
        { name: t('Burçin KOÇ'), role: t('İngilizce Öğretmeni'), image: teacher_primary_20 },
        { name: t('Deniz Simay ATILGAN'), role: t('İngilizce Öğretmeni'), image: teacher_primary_8 },
        { name: t('Pelin ŞİRİN'), role: t('İngilizce Öğretmeni'), image: teacher_primary_9 },
        { name: t('Buse RÜZGAR ATAK'), role: t('Sosyal Bilgiler Öğretmeni'), image: teacher_primary_10 },
        { name: t('Duygu YARAR'), role: t('Türkçe Öğretmeni'), image: teacher_primary_11 },
        { name: t('Betül CANDEMİR'), role: t('Fen Bilimleri Öğretmeni'), image: teacher_primary_12 },
        { name: t('Yasemin YİĞİT TOPCAN'), role: t('Fen Bilimleri Öğretmeni'), image: teacher_primary_13 },
        { name: t('Aysun GÜNGÖR'), role: t('Görsel Sanatlar Öğretmeni'), image: teacher_primary_14 },
        { name: t('Dilara AKAR'), role: t('Bilişim Teknolojileri Öğretmeni'), image: teacher_primary_15 },
        { name: t('Metin Ozan DEMİRELLİ'), role: t('Beden Eğitimi Öğretmeni'), image: teacher_primary_16 },
        { name: t('Arya YILMAZ'), role: t('Müzik Öğretmeni'), image: teacher_primary_19 },
        { name: t('Esin ÇAĞATAY KOÇMAR '), role: t('Öğrenci İşleri'), image: teacher_primary_17 },
        { name: t('Kezban ATASOY'), role: t('Güvenlik Görevlisi'), image: teacher_primary_18 },
        // Diğer öğretmenler...
    ];

    const highSchoolTeachers = [
        { name: t('Gönül KOPARAL'), role: t('Okul Müdürü'), image: manager7 },
        { name: t('Bülent TEKİN'), role: t('Lise Müdür Yardımcısı'), image: manager4 },
        { name: t('Şükran AKTAN'), role: t('Rehber Öğretmen ve Psikolojik Danışman'), image: teacher_high_1 },
        { name: t('Selmin KÖK'), role: t('Matematik Öğretmeni'), image: teacher_high_5 },
        { name: t('Kemal ÇOPUR'), role: t('Matematik Öğretmeni'), image: teacher_high_4 },
        { name: t('Turgut DEMİR'), role: t('Matematik Öğretmeni'), image: teacher_high_6 },
        { name: t('İkram DEMİR'), role: t('Matematik Öğretmeni'), image: teacher_high_3 },
        { name: t('Fuat ERTUĞRUL'), role: t('Kimya Öğretmeni'), image: teacher_high_15 },
        { name: t('Aylin TUTAR'), role: t('Kimya Öğretmeni'), image: teacher_high_17 },
        { name: t('Ahmet TUTAR'), role: t('Fizik Öğretmeni'), image: teacher_high_13 },
        { name: t('Celalettin SEZGİN'), role: t('Fizik Öğretmeni'), image: teacher_high_14 },
        { name: t('İsmet ŞİMŞEK'), role: t('Fizik Öğretmeni'), image: teacher_high_21 },
        { name: t('Jale ÇELİK'), role: t('Biyoloji Öğretmeni'), image: teacher_high_16 },
        { name: t('Mehmet ÇAKIR'), role: t('Türkçe Öğretmeni'), image: teacher_high_12 },
        { name: t('Hanife GÜNEY'), role: t('Türk Dili ve Edebiyatı Öğretmeni'), image: teacher_high_11 },
        { name: t('Uğur KARABALAK'), role: t('Türk Dili ve Edebiyatı Öğretmeni'), image: teacher_high_22 },
        { name: t('Mert DÜZEN'), role: t('Tarih Öğretmeni'), image: teacher_high_8 },
        { name: t('Mustafa Merdan DEMİR'), role: t('Coğrafya Öğretmeni'), image: teacher_high_9 },
        { name: t('Kübra BAYRAM'), role: t('Din Kültürü ve Ahlak Bilgisi Öğretmeni'), image: teacher_high_10 },
        { name: t('Cemil Erdem BİLGİÇ'), role: t('İngilizce Öğretmeni'), image: teacher_high_7 },
        { name: t('İrem ÇAĞLAR'), role: t('Ölçme Değerlendirme Uzmanı'), image: teacher_high_2 },
        { name: t('Mustafa AYDOĞAN'), role: t('Beden Eğitimi Öğretmeni'), image: teacher_high_18 },
        { name: t('Aysun GÜNGÖR'), role: t('Görsel Sanatlar Öğretmeni'), image: teacher_primary_14 },
        { name: t('Arya YILMAZ'), role: t('Müzik Öğretmeni'), image: teacher_primary_19 },
        { name: t('Dilara AKAR'), role: t('Bilişim Teknolojileri Öğretmeni'), image: teacher_primary_15 },
        { name: t('Mine ALTUNIŞIK'), role: t('Öğrenci İşleri'), image: teacher_high_19 },
        { name: t('Hilal CANER ARPA'), role: t('Muhasebe Birimi'), image: teacher_high_20 },

        // Diğer öğretmenler...
    ];

    const management = [
        { name: t('Gönül KOPARAL'), role: t('OKUL MÜDÜRÜ'), image: manager2 },
        { name: t('Leyla Funda GÜNDAY'), role: t('İLKOKUL & ORTAOKUL MÜDÜRÜ'), image: manager3 },
        { name: t('Bülent TEKİN'), role: t('LİSE MÜDÜR YARDIMCISI'), image: manager4 },
        { name: t('Aybüke KOÇAK'), role: t('İLKOKUL & ORTAOKUL MÜDÜR YARDIMCISI'), image: manager5 },
        { name: t('Esra DÜZEN'), role: t('KURS MÜDÜR YARDIMCISI'), image: manager6 },
        // Diğer yönetim üyeleri...
    ];

    const founder = {
        name: t('İlker İhsan Recep NAFİLE'),
        role: t('KURUCU'),
        image: manager1,
    };

    const data =
        activeTab === "primary"
            ? primaryTeachers
            : activeTab === "highSchool"
                ? highSchoolTeachers
                : management;

    return (
        <div>
            <div className="empty-space_init"></div>
            <div className="empty-space"></div>
            <section data-type="component-text">
                <section className="container pt-5 pb-5">
                    <div className="tabs">
                        <button
                            className={activeTab === "management" ? "active" : ""}
                            onClick={() => setActiveTab("management")}
                        >
                            {t('management')}
                        </button>
                        <button
                            className={activeTab === "primary" ? "active" : ""}
                            onClick={() => setActiveTab("primary")}
                        >
                            {t('primary_school')}
                        </button>
                        <button
                            className={activeTab === "highSchool" ? "active" : ""}
                            onClick={() => setActiveTab("highSchool")}
                        >
                            {t('high_school')}
                        </button>

                    </div>

                    {/* Eğer sekme "management" ise kurucuyu göster */}
                    {activeTab === "management" && (
                        <div className="founder-card" onClick={() => setSelectedImage(founder.image)}>
                            <img src={founder.image} alt={founder.name} />
                            <h2>{founder.name}</h2>
                            <p>{founder.role}</p>
                        </div>
                    )}

                    {/* Management ve diğer gruplar */}
                    <div className="grid-container">
                        {data.map((person, index) => (
                            <div
                                key={index}
                                className="teacher-card"
                                onClick={() => setSelectedImage(person.image)}
                            >
                                <img src={person.image} alt={person.name} />
                                <h3>{person.name}</h3>
                                <p>{person.role}</p>
                            </div>
                        ))}
                    </div>

                    {selectedImage && (
                        <div className="modal" onClick={() => setSelectedImage(null)}>
                            <img src={selectedImage} alt="Selected" />
                        </div>
                    )}
                </section>
            </section>
        </div>
    );
}

export default Staff;